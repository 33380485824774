import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import * as projectStyles from "../components/project.module.css"
import { Link } from "gatsby"
import Video from "../videos/pluto-30-cropped.mp4"
import videoMobile from "../videos/naturrepublik-30-cropped.mp4"
import videoTablet from "../videos/tablet-screen-recording.mp4"

const PlutoPage = () => (
  <div>
    <Seo title="Webdesign für Pluto Technologies Limited - Altergott Studios" lang="de" description="Eine neue Website wurde für das Startup Pluto Technlogies Limited erstellt, um Produkte online zu verkaufen sowie bestehenden Kunden zu helfen." />

    <Layout>
      <div>
        <Link to="https://pluto-website-483da2.webflow.io/">
          <div className={projectStyles.videoWrapper}>
            <video
              loop
              muted
              autoPlay
              playsInline
              className={projectStyles.video}
            >
              <source src={Video} type="video/mp4" />
            </video>
            <video
              loop
              muted
              autoPlay
              playsInline
              className={projectStyles.videoMobile}
            >
              <source src={videoMobile} type="video/mp4" />
            </video>
            <video
              loop
              muted
              autoPlay
              playsInline
              className={projectStyles.videoTablet}
            >
              <source src={videoTablet} type="video/mp4" />
            </video>
          </div>
        </Link>

        <div className={projectStyles.projectGrid}>
          <div>
            <h3 className={projectStyles.uppercase}>Kunde</h3>

            <p>Pluto Technologies Limited (Mitgründer)</p>
            <Link
              to="https://pluto-website-483da2.webflow.io/"
              className={projectStyles.projectLink}
            >
              plutosmarthome.com
            </Link>
          </div>
          <div>
            <h3 className={projectStyles.uppercase}>Projektbeschreibung</h3>

            <p>Leistungen: Webdesign, SEO, Domainregistrierung</p>
            <p>
              Eine neue Website wurde für das Startup Pluto Technlogies Limited
              erstellt.
            </p>
            <p>
              Das primäre Ziel der Website war der Verkauf von einem Smart Licht
              Dimmer.
            </p>
            <p>
              Die Website verfügt über einen Einkaufswagen, Shop, Hilfezentrum
              und Kontaktformular.
            </p>
            <p>
              Die Website ist zweisprachig (Deutsch und Englisch) und
              smartphone-freundlich.
            </p>
          </div>
          <div>
            <h3 className={projectStyles.uppercase}>Auswirkung</h3>

            <p>
              Die Website hat den Mehrwert des neuen Produkts kommuniziert und
              potentiellen Kunden viele Ängste weggenommen, z.B durch einen
              Kompatibilitätstest und ein Hilfezentrum.
            </p>
            <p>
              Das markentreue Design hat die Markenwerte und Markendidentität
              zur Öffentlichkeit kommuniziert und verstärkt.
            </p>
          </div>
        </div>
      </div>
    </Layout>
  </div>
)

export default PlutoPage
